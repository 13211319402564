<template>
  <div id="customer-edit-add">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'EditCustomer'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>

    <transition name="fade">
      <div class="content-center">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="$t('EditCustomer')">

            <div class="vx-col">

              <div class="vx-row flex">
                <!-- tab 1 content -->

                <div class="vx-col md:w-1/2">
                  <div class="vx-row mb-2">
                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        v-validate="'required'"
                        validate-on="change"
                        :name="$t('FirstName')"
                        class="w-full" :label-placeholder="$t('FirstName')"
                        v-model="firstName"/>
                      <span class="text-danger text-sm">{{ errors.first($t('FirstName')) }}</span>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        v-validate="'required'"
                        validate-on="change"
                        :name="$t('LastName')"
                        class="w-full" :label-placeholder="$t('LastName')"
                        v-model="lastName"/>
                      <span class="text-danger text-sm">{{ errors.first($t('LastName')) }}</span>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        v-validate="'required'"
                        validate-on="change"
                        :name="$t('Address')"
                        class="w-full" :label-placeholder="$t('Address') + '*'"
                        v-model="address"/>
                      <span class="text-danger text-sm">{{ errors.first($t('Address')) }}</span>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        v-validate="'required'"
                        validate-on="change"
                        :name="$t('HouseNo')"
                        class="w-full" :label-placeholder="$t('HouseNo') + '*'"
                        v-model="houseNo"/>
                      <span class="text-danger text-sm">{{ errors.first($t('HouseNo')) }}</span>
                    </div>
                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        v-validate="'required'"
                        validate-on="change"
                        name="postal-code"
                        type="number"
                        class="w-full" :label-placeholder="$t('PostalCode')"
                        v-model="postalCode"/>
                      <span class="text-danger text-sm">{{ errors.first('postal-code') }}</span>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        v-validate="'required'"
                        validate-on="change"
                        name="city"
                        class="w-full" :label-placeholder="$t('City')"
                        v-model="city"/>
                      <span class="text-danger text-sm">{{ errors.first('city') }}</span>
                    </div>

<!--                    <div class="vx-col w-full md:w-1/2 mt-3">-->
<!--                      <vs-input-->
<!--                        v-validate="'required|min:3'"-->
<!--                        validate-on="change"-->
<!--                        :name="$t('Username')"-->
<!--                        class="w-full" :label-placeholder="$t('Username')"-->
<!--                        v-model="username"/>-->
<!--                      <span class="text-danger text-sm">{{ errors.first($t('Username')) }}</span>-->
<!--                    </div>-->


                    <div class="vx-col w-full md:w-1/2 mt-3">

                      <div class="vs-row flex py-2">
                        <vs-switch color="dark" icon-pack="feather" vs-icon-on="icon-check-circle"
                                   vs-icon-off="icon-slash" v-model="enableWebView">
                          <span slot="on">{{ $t('YES') }}</span>
                          <span slot="off">{{ $t('NO') }}</span>
                        </vs-switch>
                        <p class="px-3">{{ $t('EnableWebSales') }}</p>
                      </div>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-select
                        autocomplete
                        class=" w-full pb-3"
                        :label="$t('BalanceType')"
                        v-model="selectedBalanceType"
                        :placeholder="$t('BalanceType')"
                      >
                        <vs-select-item :key="index" :value="f.id" :text="f.name" v-for="(f,index) in balanceTypeList"/>
                      </vs-select>
                    </div>

<!--                    <div class="vx-col w-full md:w-1/2 mt-3">-->
<!--                      <vs-select autocomplete v-model="template" class="w-full select-large"-->
<!--                                 :disabled="activeUserInfo.account_role === 1"-->
<!--                                 :label="$t('Template')">-->
<!--                        <vs-select-item :key="index" :value="item.key" :text="item.name"-->
<!--                                        v-for="(item,index) in templates" class="w-full"/>-->
<!--                      </vs-select>-->
<!--                    </div>-->


                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        v-validate="'required'"
                        validate-on="change"
                        name="mobile"
                        class="w-full" :label-placeholder="$t('Mobile') + '*'"
                        v-model="mobile"/>
                      <span class="text-danger text-sm">{{ errors.first('mobile') }}</span>
                    </div>


                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        name="mobile1"
                        class="w-full" :label-placeholder="$t('AlternativeMobile1')"
                        v-model="mobile1"/>
                      <span class="text-danger text-sm">{{ errors.first('mobile1') }}</span>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        name="mobile2"
                        class="w-full" :label-placeholder="$t('AlternativeMobile2')"
                        v-model="mobile2"/>
                      <span class="text-danger text-sm">{{ errors.first('mobile2') }}</span>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        v-validate="'numeric'"
                        validate-on="change"
                        :name="$t('Landline')"
                        class="w-full" :label-placeholder="$t('Landline')"
                        type="number"
                        v-model="landline"/>
                      <span class="text-danger text-sm">{{ errors.first($t('Landline')) }}</span>
                    </div>


                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        validate-on="change"
                        name="stnr"
                        class="w-full" :label-placeholder="$t('StNr') + '*'"
                        v-model="stNr"/>
                      <span class="text-danger text-sm">{{ errors.first('stnr') }}</span>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        validate-on="change"
                        name="ustldnr"
                        class="w-full" :label-placeholder="$t('ustldnr') + '*'"
                        v-model="ustldnr"/>
                      <span class="text-danger text-sm">{{ errors.first('ustldnr') }}</span>
                    </div>


                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        v-validate="'required|email'"
                        validate-on="change"
                        name="email"
                        class="w-full" :label-placeholder="$t('EmailId') + '*'"
                        v-model="email"/>
                      <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                    </div>


                    <div class="vx-col w-full md:w-1/2 mt-3" v-if="activeUserInfo.internal_role == 0 || activeUserInfo.staff_role == 1">
                      <vs-select
                        autocomplete
                        class=" w-full pb-3"
                        :label="$t('Referer')"
                        v-model="selectedReferer"
                        :placeholder="$t('Referer') + ' *'"
                      >
                        <vs-select-item :key="0" :value="0" text="None" />
                        <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"
                                        v-for="(customer,index) in managerAccounts"/>
                      </vs-select>
                    </div>
<!--                    <div class="vx-col w-full md:w-1/2 mt-3">-->
<!--                      <vs-select-->
<!--                        autocomplete-->
<!--                        class=" w-full pb-3"-->
<!--                        :label="$t('Referer')"-->
<!--                        v-model="selectedReferer"-->
<!--                        :placeholder="$t('Referer') + ' *'"-->
<!--                      >-->
<!--                        <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"-->
<!--                                        v-for="(customer,index) in (selectedManager == null ? [] : selectedManager.staff_accounts)"/>-->
<!--                      </vs-select>-->
<!--                    </div>-->

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-select
                        autocomplete
                        class=" w-full pb-3"
                        :label="$t('Flag')"
                        v-model="selectedFlag"
                        :placeholder="$t('Flag')"
                      >
                        <vs-select-item :key="index" :value="f.id" :text="f.name" v-for="(f,index) in flags"/>
                      </vs-select>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        validate-on="change"
                        :name="$t('Organisation')"
                        class="w-full" :label-placeholder="$t('Organisation')"
                        v-model="organisation"/>
                      <span class="text-danger text-sm">{{ errors.first($t('Organisation')) }}</span>
                    </div>
<!--                    <div class="vx-col w-full md:w-1/2 mt-3">-->
<!--                      <vs-input-->
<!--                        validate-on="change"-->
<!--                        :name="$t('Company')"-->
<!--                        class="w-full" :label-placeholder="$t('Company')"-->
<!--                        v-model="company"/>-->
<!--                      <span class="text-danger text-sm">{{ errors.first($t('Company')) }}</span>-->
<!--                    </div>-->

                  </div>
                </div>


                <div class="vx-col md:w-1/2" style="">

                  <div class="vx-col w-full mt-3">
                    <vs-select autocomplete v-model="template" class="w-full select-large"
                               :disabled="activeUserInfo.account_role === 1"
                               :label="$t('Template')">
                      <vs-select-item :key="index" :value="item.key" :text="item.name"
                                      v-for="(item,index) in templates" class="w-full"/>
                    </vs-select>
                  </div>

<!--                  <h2 class="py-3"><strong>{{ $t('Preview') }}</strong></h2>-->
<!--                  <a v-if="template == 1" href="/images/templates/1.png" target="_blank">-->
<!--                    <img src="/images/templates/1.png" alt="template-1-404" class="mx-auto mb-4 max-w-full">-->
<!--                  </a>-->
<!--                  <a v-else href="/images/templates/0.png" target="_blank">-->
<!--                    <img src="/images/templates/0.png" alt="template-0-404" class="mx-auto mb-4 max-w-full">-->
<!--                  </a>-->


                <template v-if="customer.organisation_type < 2">

                  <h2 class="py-3"><strong>{{ $t('InvoiceLogo') }}</strong></h2>
                  <div class="flex w-full items-center justify-center bg-grey-lighter">
                    <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue">
                      <img v-if="logoImageSrc" :src="logoImageSrc" style="max-height: 100px;" />
                      <svg v-else class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                      </svg>
                      <span class="mt-2 text-base leading-normal">{{ $t('SelectImage') }}</span>
                      <input type='file'
                             class="hidden"
                             accept="image/*" ref="invoiceHeaderFileUpload"
                             @change="selectedlogoImage"/>
                    </label>
                  </div>


                  <h2 class="py-3"><strong>{{ $t('LoginLogoImage') }}</strong></h2>
                  <div class="flex w-full items-center justify-center bg-grey-lighter">
                    <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue">
                      <img v-if="loginLogoImageSrc" :src="loginLogoImageSrc" style="max-height: 100px;" />
                      <svg v-else class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                      </svg>
                      <span class="mt-2 text-base leading-normal">{{ $t('SelectImage') }}</span>
                      <input type='file'
                             class="hidden"
                             accept="image/*" ref="invoiceFooterFileUpload"
                             @change="selectedloginLogoImage"/>
                    </label>
                  </div>

                  <div class="vx-row mb-2">


                    <template v-if="activeUserInfo.account_role == 0">

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        validate-on="change"
                        :name="$t('Director')"
                        class="w-full" :label-placeholder="$t('Director')"
                        v-model="directorName"/>
                      <span class="text-danger text-sm">{{ errors.first($t('Director')) }}</span>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        validate-on="change"
                        :name="$t('BankName')"
                        class="w-full" :label-placeholder="$t('BankName')"
                        v-model="bankName"/>
                      <span class="text-danger text-sm">{{ errors.first($t('BankName')) }}</span>
                    </div>
                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        validate-on="change"
                        :name="$t('HRBNr')"
                        class="w-full" :label-placeholder="$t('HRBNr')"
                        v-model="hrbNr"/>
                      <span class="text-danger text-sm">{{ errors.first($t('HRBNr')) }}</span>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                      <vs-input
                        validate-on="change"
                        :name="$t('IBan')"
                        class="w-full" :label-placeholder="$t('IBan')"
                        v-model="iban"/>
                      <span class="text-danger text-sm">{{ errors.first($t('IBan')) }}</span>
                    </div>

                    <div class="vx-col w-full md:w-1/2 mt-3">
                    <vs-input
                      validate-on="change"
                      :name="$t('BIC')"
                      class="w-full" :label-placeholder="$t('BIC')"
                      v-model="bic"/>
                    <span class="text-danger text-sm">{{ errors.first($t('BIC')) }}</span>
                  </div>

                    </template>
<!--                  <div class="vx-col w-full md:w-1/2 mt-3" v-if="customer.organisation_type != 1">-->
<!--                    <vs-input-->
<!--                      validate-on="change"-->
<!--                      :name="$t('SMSSenderID')"-->
<!--                      class="w-full" :label-placeholder="$t('SMSSenderID')"-->
<!--                      v-model="smsSenderId"/>-->
<!--                    <span class="text-danger text-sm">{{ errors.first($t('SMSSenderID')) }}</span>-->
<!--                  </div>-->
<!--                  <div class="vx-col w-full md:w-1/2 mt-3">-->
<!--                    <vs-input-->
<!--                      validate-on="change"-->
<!--                      :name="$t('SubDomain')"-->
<!--                      class="w-full" :label-placeholder="$t('SubDomain')"-->
<!--                      v-model="subDomain"/>-->
<!--                    <span class="text-danger text-sm">{{ errors.first($t('SubDomain')) }}</span>-->
<!--                  </div>-->

                  </div>

                </template>

                </div>
              </div>

              <div class="mt-6"></div>

              <div class="vx-row">
                <vs-button style="margin: auto;" @click="saveUserDetails()">{{ $t('Save') }}</vs-button>
              </div>
            </div>

          </vx-card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'AddCustomer',
  components: {
    'v-select': vSelect,
  },
  computed: {
    validateStep() {
      return !this.errors.any() && this.postalCode !== '' && this.city !== ''
        && this.username !== ''
    },

    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    users() {
      return this.$store.state.user.users
    },
  },
  props: {
    customerId: {
      type: String | Number,
    },
    customer: null,
  },
  data() {
    return {
      customer: {},
      managerAccounts: [],
      template: 0,
      firstName: '',
      lastName: '',
      houseNo: '',
      address: '',
      postalCode: '',
      city: '',
      username: '',
      mobile: '',
      mobile1: '',
      mobile2: '',
      landline: '',
      stNr: '',
      ustldnr: '',
      email: '',
      hrbNr: '',
      iban: '',
      bic: '',
      smsSenderId: '',
      directorName: '',
      subDomain: '',
      organisation: '',
      // company: '',
      bankName: '',
      logoImage: null,
      logoImageSrc: null,
      loginLogoImage: null,
      loginLogoImageSrc: null,
      selectedReferer: null,
      dummySelectedReferer: null,
      selectedFlag: 2,
      enableWebView: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Customers', i18n: 'Customers', url: '/customers/view' },
        { title: 'Edit Customer', i18n: 'EditCustomer', active: true },
      ],
      templates: [
        {
          key: 0,
          name: 'Template 1',
        },
        {
          key: 1,
          name: 'Template 2',
        },
        {
          key: 2,
          name: 'Template 3',
        },
        {
          key: 3,
          name: 'Template 4',
        },
        {
          key: 4,
          name: 'Template 5',
        },
        {
          key: 5,
          name: 'Template 6',
        },
      ],
      selectedBalanceType: 0,
      balanceTypeList: [
        {
          id: 0,
          name: 'Plus System',
        },
        {
          id: 1,
          name: 'Minus System',
        },
      ],
      flags: [
        {
          id: 0,
          name: 'Red',
        },
        {
          id: 1,
          name: 'Green',
        },
        {
          id: 2,
          name: 'Yellow',
        },
      ],
    }
  },
  methods: {
    async selectedlogoImage() {
      this.logoImage = this.$refs.invoiceHeaderFileUpload.files[0]
      const readURL = (file) => new Promise((res, rej) => {
        const reader = new FileReader()
        reader.onload = (e) => res(e.target.result)
        reader.onerror = (e) => rej(e)
        reader.readAsDataURL(file)
      })

      this.logoImageSrc = await readURL(this.logoImage)
    },
    async selectedloginLogoImage() {
      this.loginLogoImage = this.$refs.invoiceFooterFileUpload.files[0]
      const readURL = (file) => new Promise((res, rej) => {
        const reader = new FileReader()
        reader.onload = (e) => res(e.target.result)
        reader.onerror = (e) => rej(e)
        reader.readAsDataURL(file)
      })

      this.loginLogoImageSrc = await readURL(this.loginLogoImage)
    },
    openCustomerList() {
      this.$router.push({ name: 'customers-view' })
    },
    async saveUserDetails() {
      if (!this.mobile.startsWith('+')) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please correct mobile no with country code (+xx)',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      }
      if (this.mobile1) {
        if (!this.mobile1.startsWith('+')) {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: 'Please correct alternative mobile no with country code (+xx)',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        }
      }
      if (this.mobile2) {
        if (!this.mobile2.startsWith('+')) {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: 'Please correct alternative mobile no with country code (+xx)',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        }
      }
      if (this.validateStep) {
        const payload = {
          first_name: this.firstName,
          last_name: this.lastName,
          postal: this.postalCode,
          city: this.city,
          // username: this.username,
          customer_id: this.customerId,
          template: this.template || 0,
          enable_web_view: this.enableWebView ? 1 : 0,
          balance_type: this.selectedBalanceType,
          house_no: this.houseNo,
          address: this.address,
          mobile: this.mobile,
          mobile_1: this.mobile1,
          mobile_2: this.mobile2,
          land_line: this.landline,
          stnr: this.stNr,
          ustldnr: this.ustldnr,
          email: this.email,
          hrb_nr: this.hrbNr,
          iban: this.iban,
          bic: this.bic,
          director_name: this.directorName,
          sms_sender_id: this.smsSenderId,

          sub_domain: this.subDomain,
          name: this.organisation,
          bank_name: this.bankName,
          // company: this.company,
          refer_id: this.selectedReferer,
          refer: this.selectedReferer,
          // refer_manager_id: this.selectedManager.user_id,
          flag: this.selectedFlag,

        }
        if(this.logoImage) {
          payload.logo = this.logoImage
        }
        if(this.loginLogoImage) {
            payload.login_page_logo =this.loginLogoImage
        }

        if (this.selectedReferer) {
          payload.refer_id = this.selectedReferer
          // payload.refer_manager_id = this.selectedManager.user_id
        }
        this.$vs.loading()
        const data = await this.$store.dispatch('customer/updateCustomer', payload).catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.response.statusText,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        })
        if (data) {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.$router.push({ name: 'customers-view' })
          return true
        }
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Unknown error',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      }
      return false
    },

    loadUsers() {
      this.$vs.loading()
      this.$store.dispatch('user/fetchMangerUsers', {})
        .then((data) => {
          this.managerAccounts = data.data.staff_accounts
          this.managerAccounts.forEach((user) => {
            if (user.user_name == this.dummySelectedReferer) {
              this.selectedReferer = user.user_id
            }
          })
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.$vs.loading()
    return this.$store.dispatch('customer/fetchCustomerDetails', this.customerId)
      .then((data) => {
        this.loadUsers()
        this.firstName = data.first_name
        this.lastName = data.last_name
        this.postalCode = data.postal_code
        this.city = data.city
        this.username = data.username
        this.template = data.template
        this.enableWebView = data.enable_web_view == 1
        this.selectedBalanceType = data.balance_type || 0
        this.$vs.loading.close()
        this.errorFetching = false
        this.houseNo = data.house_no
        this.address = data.address
        this.mobile = data.mobile
        this.mobile1 = data.mobile_1 == 'null' ? '' : data.mobile_1
        this.mobile2 = data.mobile_2 == 'null' ? '' : data.mobile_2
        this.landline = data.land_line == 'null' ? '' : data.land_line
        this.stNr = data.stnr
        this.ustldnr = data.ustldnr
        this.email = data.email
        this.dummySelectedReferer = data.reference
        this.selectedFlag = data.flag
        this.logoImageSrc = data.invoice_header
        this.loginLogoImageSrc = data.invoice_footer
        this.iban = data.iban
        this.bic = data.bic
        this.hrbNr = data.hrb_nr
        this.directorName = data.director_name
        this.logoImageSrc = data.logo
        this.loginLogoImageSrc = data.login_page_logo
        this.smsSenderId = data.sms_sender_id
        this.subDomain = data.sub_domain
        this.organisation = data.name
        // this.company = data.company
        this.bankName = data.bank_name

        this.customer = data;
      })
      .catch((error) => {
        console.error(error)
        this.$vs.loading.close()
        this.errorFetching = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },
}
</script>

<style lang="scss">
#customer-edit-add {
  .vs-placeholder-label {
    font-weight: bold;
    font-size: .9rem !important;
    color: rgba(0,0,0,.6) !important;
  }
  .vs-select--label {
    font-size: .9rem !important;
    color: rgba(0,0,0,.6) !important;
  }


  .c-center {
    margin: auto;
  }

  .c-center-text {
    text-align: center;
  }

}
</style>
